// eslint-disable-next-line import/no-unresolved
import auth from '@/middleware/auth'

const formTable = [
  // CREATIONS
  {
    path: '/hotels-contrate/list',
    name: 'hotels-contrate-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/ContrateHotels.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },

  {
    path: '/hotels-contrate/create',
    name: 'hotels-contrate-create',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/Contrate.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/update',
    name: 'hotels-contrate-update',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/HotelsContrate.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/hotels-asociar',
    name: 'hotels-contrate-hotels-asociar',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/HotelsContrateAsociar.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/add-tarifa',
    name: 'hotels-contrate-hotels-add-tarifa',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/HotelsContrateAddTarifa.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/add-tarifa-direct',
    name: 'hotels-contrate-hotels-add-tarifa-direct',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/HotelsContrateAddTarifaDirect.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/delete-tarifarios',
    name: 'hotels-contrate-hotels-delete-tarifarios',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/HotelsContrateDeleteTarifarios.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/seasons',
    name: 'hotels-contrate-seasons',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/SeasonHotel.vue'),

    // component: () => import('@/views/contratos/hotels/form/Season.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/prices',
    name: 'hotels-contrate-prices',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/Prices.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/lotes',
    name: 'contrate-hotels-upload-lote',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/UploadLote.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/import-files',
    name: 'contrate-hotels-import-files',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/ImportFiles.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
  {
    path: '/hotels-contrate/import-files-dingus',
    name: 'contrate-hotels-import-files-dingus',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/contratos/hotels/form/ImportFilesDingus.vue'),
    meta: {
      layout: 'content',
      middleware: auth,
      pertenece: 'contrato_hotels',
    },
  },
]

export default formTable
