export default {
  landing: {
    menu: {
      home: 'Inicio',
      about: 'Quienes somos',
      plataform: 'La plataforma',
      afiliate: 'Los afiliados',
      marcas: 'Marcas',
      team: 'Equipo de trabajo',
      history: 'Nuestra historia',
      products: 'Productos',
      parnert: 'Socios',
      testimonios: 'Clientes',
    },
    btn: {
      register: 'Registrarse',
    },
    lbl: {
      login: 'Iniciar sesión',
      changeLang: 'Cambiar idioma',
      selectLang: 'Elegir idioma',
    },
    secOne: {
      title: 'Economiza tu tiempo y tus esfuerzos para gestionar destinos, viajes y servicios turísticos',
      subtitle:
        'Amplía tus posibilidades de negocios y proporciona múltiples experiencias a tus clientes desde la comodidad de una plataforma única.',
    },
    secTwo: {
      title: 'Conecta historia, cultura, personas...',
      subtitle: 'Wamasol Plus es una plataforma de viajes business to business',
      subtitle1:
        'que promueve paquetes de turismo multidestino alrededor del mundo, con especial énfasis en el Caribe y Centroamérica. Conectamos historia, tradiciones, naturaleza, cultura, patrimonio y, sobre todo, personas.',
    },
    secThree: {
      title:
        'Orientada a satisfacer las necesidades de operación turística de las compañías de la industria de viajes en todo el mundo',
      proveedor: 'Proveedores',
      proveedor1:
        'Aerolíneas, cadenas hoteleras, rentadoras de autos, marinas, hostales, alquileres vacacionales, guías turísticos, que ofrecen sus productos con el objetivo de multiplicar sus canales de venta.',
      agency: 'Agencias de Viajes y Turoperadores',
      agency1:
        'Operadores turísticos que se registran con el objetivo de ampliar la oferta que brindan a sus clientes a través de todos sus canales de venta: web, móvil, social, presencial, telefónica.',
    },
    secFour: {
      title: 'Nuestros productos',
      prod1: 'Vuelos',
      prod2: 'Renta de autos',
      prod3: 'Excursiones',
      prod4: 'Yates y Marinas',
      prod5: 'Buceo y Pesca',
      prod6: 'Hoteles',
      prod7: 'Traslados',
      prod8: 'Paquetes',
      prod9: 'Cruceros',
      prod10: 'Turismo de Salud',
    },
    secFive: {
      title: 'Nuestros Socios',
      subtitle:
        'La plataforma está avalada por prestigiosas marcas de la industria turística en la región, que garantizan la fiabilidad de los servicios y la satisfacción del cliente final.',
    },
    secSix: {
      title: 'Estructura y Marcas',
      plus: 'Plataforma de viajes B2B,',
      plus1:
        'propiedad de Wamasol Unip Lda, que ofrece a las compañias registradas la posibilidad de expandir sus negocios:',
      plus2: 'Multiplicando los canales de venta de los proveedores, y',
      plus3: 'Ampliando la cartera de productos de las Agencias de viajes y Turoperadores afiliados.',
      tour: 'Agencia Mayorista de viajes,',
      tour1: 'fundada en Guatemala, especializada en turismo multidestino.',
      tour2:
        'Conecta a clientes de Europa, Estados Unidos y Canadá con la región del Caribe y Centro América, privilegiando en su oferta turística los destinos de Cuba y Guatemala.',
      tech: '',
      tech1: 'División de tecnologías',
      tech2:
        'de Wamasol Unip Lda, está encargada del desarrollo de aplicaciones web y móviles para comercio electrónico, tanto propias como a pedido para terceros, dirigidas principalmente a la industria turística y tiendas en línea.',
    },
    secSeven: {
      title: 'Conoce nuestro Equipo',
      favoriteFoot: 'Comida favorita:',
      favoriteFilm: 'Película favorita:',
      favoritePower: 'Superpoder favorito:',
    },
    secEight: {
      title: 'Nuestros clientes',
      opi1:
        'A través de nuestra asociación con Wamasol hemos encontrado diversas formas de trabajar para organizar todo tipo de actividades. Hemos tenido la suerte de cultivar alianzas más sólidas a lo largo de los años gracias a los excelentes servicios que ofrecen. No podríamos lograrlo sin Wamasol y sabemos que contamos con ellos para planificar nuestros eventos.',
      opi1a: 'Diretor',
      opi1b: 'Senior Manager',
      opi1c: 'Cuba Oceans Program,',
      opi1d: 'Environmental Defense Fund',
      opi2:
        'Encontramos en Wamasol verdaderos socios, que no solo nos ayudan a resolver todos los desafíos para organizar actividades en el terreno, sino que también se preocupan profundamente por el trabajo y las personas involucradas. Agradecemos enormemente a Wamasol por sus esfuerzos y esperamos continuar trabajando juntos en el futuro.',
      opi2a: 'Cuba Country Director',
      opi2b: 'Mesoamerica & Western Caribbean Program,',
      opi2c: 'Wildlife Conservation Society',
      opi3:
        'Conozco a los directores de Wamasol Tours, Fernando y Edgar, desde hace años. Son extremadamente eficientes, diligentes y amigables, y sé que puedo confiar en ellos para organizar viajes de primera clase para mí o mis amigos a los lugares especiales de Cuba. Si está buscando un recorrido memorable y un servicio personalizado, vaya con Wamasol.',
      opi3a: 'Cuba afficionado and Canadian conservation biologist',
    },
    secNine: {
      title: 'Nuestra Historia',
      20181: 'Nacimiento de Wamasol Tours como proyecto.',
      20182: 'Firma de primeros acuerdos de operación turística con OnlineTours en España, y en Cuba con el receptivo nacional Cubatur así como la cadena hotelera Islazul.',
      20183: 'Inicio de operaciones turísticas con clientes provenientes de España, Canadá y Puerto Rico.',
      20201: 'Constitución de la empresa Wamasol Tours SA en Guatemala.',
      20202: 'Creación de Wamasol Tech, división de tecnología de la compañía para el desarrollo de aplicaciones web y móviles especializadas en turismo y comercio electrónico.',
      20211: 'Inicio de la externacionalización de los proyectos de Wamasol Tech, firma del primer contrato de desarrollo para Two Way Travel en USA.',
      20221: 'Participación en la feria internacional de turismo de La Habana FITCUBA 2022. Ampliación de los contratos de Wamasol Tours con operadores turísticos en Cuba: Gaviota Tours, Viajes Cubanacán, Amistur y la Comercializadora de Servicios Médicos Cubanos (SMC)',
      20222: 'Wamasol Tech amplia su cartera de clientes con la firma de nuevos contratos de desarrollo con PROTURS (México), Viajes Kronos (España) y TravelZun (USA).',
      20223: 'Creación de Wamasol Plus, plataforma de viajes B2B para la venta de paquetes turísticos multidestino, especializada en el mundo Maya y Cuba.',
      20224: 'Inicio de las negociaciones con operadores turísticos en Guatemala, Cuba y España para la integración de productos en Wamasol Plus: TAG, INGUAT, MINTUR, IACC, IBERIA.',
      20231: 'Fundación de Wamasol Unip Lda, en Portugal, heredera de las divisiones de Tecnologías y Ventas Mayoristas de Wamasol S.A.',
      20232: 'Lanzamiento de Wamasol Plus, plataforma de viajes B2B para la venta de paquetes turísticos multidestino.',
      20233: 'Wamasol es incluida en el roster de proveedores del sistema de Agencias de las Naciones Unidas.',
      20241: 'Participación en la Bolsa de Turismo de Lisboa, en el marco de la Feria Internacional  de Lisboa.',
      20242: 'Ampliación de la cartera de productos turísticos, integración con Hotetec, Dingus, Juniper, D-edge',
      20243: 'Firma de contrato con la primera cadena hotelera portuguesa, Vila Galé',
    },
    secFooter: {
      body:
        'Wamasol Unipessoal Lda. es una empresa fundada en Portugal en 2023; encargada de operar una plataforma de viajes B2B propia, especializada en paquetes turísticos multidestinos.',
      service: 'Servicios',
      serv1: 'Vuelos',
      serv2: 'Hoteles',
      serv3: 'Traslados',
      serv4: 'Renta de autos',
      serv5: 'Excursiones',
      serv6: 'Cruceros',
      serv7: 'Buceo y Pesca',
      about: 'Sobre nosotros',
      about1: 'La plataforma',
      about2: 'Los afiliados',
      about3: 'Marcas',
      about4: 'Equipo de trabajo',
      about5: 'Nuestra historia',
      contact: 'Contacto',
    },
    footer: {
      powerd: 'Desarrollado por Wamasol Tech,',
      allRight: 'Todos los derechos reservados',
    },
  },
  plataforma: {
    title: 'Datos generales de la plataforma',
  },
  login: {
    title: 'Panel de administración',
    subTitle: 'Inicia sesión en tu cuenta',
    forgot: '¿Has olvidado tu contraseña?',
    remember: 'Recuérdame',
    newAccount: '¿Nuevo en nuestra plataforma?',
    createAccount: 'Crea una cuenta',
  },
  register: {
    title: 'La aventura comienza aquí',
    subtitle: '¡Haga que la administración de su aplicación sea fácil!',
    exist: '¿Ya tienes una cuenta?',
    btnIn: 'Inicia sesión',
    btnUp: 'Regístrame',
    titleForm: 'Formulario de Registro',
    titlePerson: 'Información General',
    subTitlePerson: 'Configura tu inf. general',
    subTitleAgente: 'Datos del agente de ventas',
    subtitlePerson: 'Información personal',
    namePerson: 'Nombre y Apellidos',
    emailPerson: 'Correo Electrónico',
    codePais: 'Código del País',
    codidoPais: 'Código país',
    noTelefo: 'Número de teléfono',
    subtitleCompany: 'Información de la Empresa',
    nameCompany: 'Nombre de la Empresa',
    siteWebCompany: 'Sitio Web',
    titleFactura: 'Información de facturación',
    configInfo: 'Configura la información',
    titleFiscal: 'Información fiscal',
    idFiscal: 'Número de  identificación fiscal',
    titleFact: 'Datos para la Facturación',
    nameFact: 'Nombre Legal del Beneficiario',
    cifNif: 'NIF/NIT',
    titleBanco: 'Información bancaria',
    dataAccount: 'Datos de la cuenta',
    noBanco: 'Número de Cuenta Bancaria',
    accountBank: 'Cuenta Bancaria',
    tipoBanco: 'Tipo de cuenta bancaria',
    tipoFiscal: 'Tipo de identificación fiscal',
    moneda: 'Moneda',
    nameBanco: 'Nombre del Banco',
    codeSWIFT: 'Código SWIFT',
    codeSucursal: 'Código Sucursal',
    address: 'Dirección del Banco',
    codePostal: 'Código Postal',
    codePostal1: 'Cod. Postal',
    locality: 'Ciudad',
    country: 'País',
    profile: 'Elige tus Perfiles',
    typeAfiliado: 'Tipo de Afiliado',
    typeAgente: 'Tipo de agente y productos autorizados',
    profileSub: 'Cuéntanos un poco sobre su Empresa',
    infoAgente: 'Información sobre el agente',
    person: 'Persona',
    idemComercial: 'Igual a la comercial',
    nameLegal: 'Nombre Legal',
    nameComercial: 'Nombre Comercial',
    msgExistCompany: 'Lo sentimos ya usted esta registrado. Por favor contacte a su administrador.',
    msgExistEmailCompany: 'Lo sentimos ya el email {email} está registrado. Por favor contacte a su administrador.',
    titleSolicitude: 'Solicitud realizada desde',
    addressB2B: 'Dirección del B2B',
    streep: 'Calle',
    noApto: 'Número o Apto',
    codPostal: 'Cod. Postal',
  },
  forgot: {
    title: '¿Has olvidado tu contraseña?',
    subtitle: 'Ingrese su correo electrónico y le enviaremos instrucciones para restablecer su contraseña',
    btn: 'Enviar enlace de reinicio',
    btnBack: 'Atrás para iniciar sesión',
    errorUserInex: 'Lo sentimos pero no hemos encontrado a {email} en nuestro sistema.',
    errorUserInac: 'Lo sentimos pero {email} no está activo en nuestro sistema.',
    sendEmail: 'Se ha enviado notificación a {email}. Siga los pasos para recuperar su cuenta',
  },
  reset: {
    title: 'Restablecer la contraseña',
    subtitle: 'su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente',
    btn: 'Establecer nueva contraseña',
    btnBack: 'Atrás para iniciar sesión',
    msgSuccess: 'Contraseña reestablecida. Por favor inicie sesión',
  },
  activeAcount: {
    title: 'Active su Cuenta',
    subTitle: 'con estas credenciales podrá administrar su Empresa',
    password: 'Nueva Contraseña',
    confirmPassword: 'Confirme Contraseña',
    btn: 'Activar',
    msgInvalidToken: 'Su token es inválido',
    msgCaduquedToken:
      'El enlace que has seguido ha caducado. Estás visitando una página que ha sido generada para activar un servicio específico en {item}. Si crees que es un error, le pedimos nos contacte de inmediato.',
    msgSuccess: 'Usuario activado. Por favor inicie sesión',
  },
  lbl: {
    ocupations: 'Ocupaciones',
    month: 'Mes',
    price: 'Precio',
    prices: 'Precios',
    changePrice: 'Cambiar Precio',
    changeSeat: 'Cambiar Cant. Asientos',
    vigenteAl: 'vigente del {from} al {to}',
    file: 'Archivo',
    email: 'Correo electrónico',
    emails: 'Correos electrónicos',
    frecuenciasContrate: 'Frecuencias del Contrato',
    password: 'Contraseña',
    old_password: 'Actual Contraseña',
    new_password: 'Nueva Contraseña',
    confirmation_password: 'Confirmar Contraseña',
    min_length_password: 'Asegúrate de que tenga al menos 6 caracteres.',
    user: 'Usuario',
    name: 'Nombre',
    oferta: 'Oferta',
    monto: 'Monto',
    daysBefore: 'Días previos',
    update: 'Actualizada',
    publicada: 'Publicada',
    cancelada: 'Cancelada',
    suplementReducc: 'Suplementos y Reducciones',
    priceByNight: 'Precio por noche',
    priceByPax: 'Precio por pax',
    priceByRoom: 'Precio por habitación',
    priceByPaxDoble: 'Precio por pax en ocupación doble',
    priceByRoomDoble: 'Precio por hab. en ocupación doble',
    priceByPaxSimple: 'Precio por pax en ocupación sencilla',
    priceByRoomSimple: 'Precio por hab. en ocupación sencilla',
    priceByPaxTriple: 'Precio por pax en ocupación triple',
    priceByRoomTriple: 'Precio por hab. en ocupación triple',
    priceFirstChild: 'Precio para el primer niño ({years} años)',
    priceSecondChild: 'Precio para el segundo niño ({years} años)',
    priceInfant: 'Precio para los infantes (menores de {year} años)',
    beneficiosAdd: 'Beneficios adicionales',
    doble: 'Doble',
    simple: 'Sencilla',
    triple: 'Triple',
    firstChild: '1er. niño',
    secondChild: '2do. niño',
    infant: 'Infantes',
    confirmDateUpdate: 'Confirmar la fecha de actualización',
    canceleDateUpdate: 'Cancelar la fecha de actualización',
    nameOlds: 'Nombres anteriores',
    nameReference: 'La Referencia será exclusiva para los Gestores y nunca será mostrada a los clientes finales.',
    nameSecond: 'Segundo Nombre (si figura en documento)',
    apellidos: 'Apellidos',
    birthday: 'Fecha de nacimiento',
    nationality: 'Nacionalidad',
    sexMasc: 'Masculino',
    sexFem: 'Femenino',
    sexOther: 'Otro',
    sexo: 'Sexo',
    leng: 'Idioma',
    noDocument: 'Número de documento',
    dateCaduque: 'Fecha de caducidad',
    cardFidelidad: 'Tarjeta de fidelidad',
    noCardFidelidad: 'Número',
    addCardFidelidad: 'Agregar tarjeta de fidelidad',
    hideCardFidelidad: 'Ocultar tarjeta de fidelidad',
    contactPasajero: 'Contactos del pasajero',
    codeOFAC: 'Código OFAC',
    reserveConfirm: 'Reserva confirmada',
    reserva: 'Reserva',
    emition: 'Emisión',
    claveConfirm: 'Clave de confirmación',
    noConfirm: 'Nº confirmación',
    operaLocal: 'Operador Local',
    atension: 'ATENCIÓN',
    atension1:
      'Este voucher carece de validez si no tiene su clave de confirmación la cual debe coincidir con la enviada por el proveedor.',
    atension2: 'El pasajero es responsable de la validez de su pasaporte u otra documentación requerida.',
    atension3: 'Sujeto a aprobación gubernamental.',
    ticketNumber: 'Ticket Number',
    locator: 'Locator',
    pnr: 'PNR',
    contactEmergency: 'Contacto de emergencia',
    estableceContactEmergency: 'Establecer este contacto de emergencia a todos los pasajeros',
    nameSurname: 'Nombre y Apellidos',
    finallyReserve: 'Finalizar Reserva',
    finallyReserveText1:
      'Puedes emitir billetes en la sección Reservas. La reserva se cancelará automáticamente al finalizar este período.',
    finallyReserveText2:
      'La aerolínea tiene el derecho de cancelar la reserva hasta que caduque el tiempo límite del billete.',
    note: 'Nota',
    finallyReserveText3: 'Al hacer clic en Reservar, aceptas con',
    finallyReserveText4:
      'y confirmas que recibiste el consentimiento del cliente para compartir sus datos personales y procesarlos para reservar un billete aéreo.',
    billetesFligh: 'Billetes aéreos',
    rentaCar: 'Renta del auto',
    rentaCarByDay: 'Renta de auto x día',
    total: 'Total',
    inTotal: 'en total',
    night: 'noche',
    totalDesde: 'Total desde',
    byDayDesde: 'Por día desde',
    priceTotal: 'Precio total',
    priceByCliente: 'Precio de venta público',
    otherCarge: 'Otros cargos y suplementos',
    payOnline: 'A pagar en línea',
    payRent: 'A pagar en la rentadora',
    payAloja: 'Pagar en el alojamiento',
    incluyeComition: 'Incluye comisión',
    incluyeMargenCom: 'Incluye margen comercial',
    incluyeDescuento: 'Incluye descuento',
    cargoSuplement: 'Cargos y Suplementos',
    totalPay: 'Total a pagar',
    totalPayOnline: 'Total a pagar en línea',
    totalPayRent: 'Total a pagar en la rentadora',
    descuento: 'Descuento',
    addDescuento: 'Agregar Descuento',
    precioInitial: 'Precio inicial',
    conMarkups: 'con Markup',
    conDescuento: 'con Descuento',
    changeFoto: 'Cambiar foto',
    infoFoto: 'Permitidos JPG, GIF o PNG',
    changePassword: 'Cambiar contraseña',
    identificador: 'Identificador',
    color: 'Color',
    code: 'Código',
    slug: 'Slug',
    active: 'Activo',
    canceled: 'Cancelado',
    vencido: 'Vencido',
    inactive: 'Inactivo',
    showFront: 'Registro',
    description: 'Descripción',
    itemsPerPage: 'Por página',
    actions: 'Acciones',
    rol: 'Rol',
    permisoEdit: 'Actualizar permiso',
    perfil: 'Perfil',
    typeProduct: 'Tipo de Producto',
    products: 'Productos',
    pcFee: 'Porcentaje o Fee',
    pc: 'Porcentaje',
    fee: 'Valor',
    infoAdd: 'Información Adicional',
    info: 'Información',
    ventaDirectas: 'Ventas directas',
    valor: 'Valor',
    pc1: '%',
    all: 'Todas',
    company: 'Empresa',
    contacto: 'Contacto',
    profiles: 'Perfiles',
    dateSoliciti: 'Fecha Solicitud',
    manageSolic: 'Gestionar Aprobación',
    acceptSolic: 'Aprobar Solicitud',
    denegSolic: 'Denegar Solicitud',
    address: 'Dirección',
    telefonos: 'Teléfonos',
    telefono: 'Teléfono',
    horarios: 'Horarios',
    horariosDe: 'Horarios de',
    verifyHorarios: 'Verifique los horarios',
    horario: 'Horas',
    from: 'Desde',
    apertura: 'Apertura',
    cierre: 'Cierre',
    to: 'Hasta',
    ida: 'Ida',
    ida_vuelta: 'Ida y vuelta',
    nultiple_destino: 'Múltiples destinos',
    back: 'Vuelta',
    addFligth: 'Agregar vuelo',
    longitud: 'Longitud',
    latitud: 'Latitud',
    showMap: 'Mostrar Mapa',
    hideMap: 'Ocultar Mapa',
    airport: 'Aeropuerto',
    airports: 'aeropuertos',
    city: 'Ciudad',
    state: 'Estado',
    states: 'Estados',
    zip: 'Zip',
    country: 'País',
    countries: 'Paises',
    provinceEstado: 'Provincia / Estado',
    locality: 'Localidad',
    localities: 'Localidades',
    typeAfiliado: 'Tipo Afiliado',
    createAdmin: 'Crear Administrador',
    dataAdmin: 'Datos del Administrador',
    promover: 'Promover',
    dateVuelo: 'Fecha del vuelo',
    dateArrival: 'Fecha y hora de llegada',
    vuelo: 'Vuelo',
    de: 'de',
    a: 'a',
    dia: 'día',
    day: 'días',
    mas: 'más de',
    vuelos: 'vuelos',
    cars: 'autos',
    contratos: 'contratos',
    listContratos: 'Lista de contratos',
    selectVuelo: 'Seleccionar vuelo',
    contrateInsert: 'Debe especificar primero el operador o aerolínea para poder seleccionar el número de vuelo.',
    ruta: 'Ruta',
    numeroVuelo: 'Número de vuelo',
    operador: 'Operado por',
    aeronave: 'Aeronave',
    origen: 'Origen',
    terminal: 'Terminal',
    puerta: 'Puerta',
    destino: 'Destino',
    iata: 'Código IATA',
    checkIn: 'Despacho',
    llegada: 'Llegada',
    departure: 'Salida',
    hour_departure: 'Hora de salida',
    arrival: 'Arribo',
    hour_arrival: 'Hora de llegada',
    durationVuelo: 'Duración del vuelo',
    durationEscala: 'Duración Escala',
    durationEscalaRango: 'hasta {time} horas',
    durationViaje: 'Duración del viaje',
    escala: 'escala',
    escalaIn: 'Escala en',
    escalad: 'escalas',
    escalas: 'Escalas',
    escala1: 'Todos los vuelos',
    escala2: 'Directo',
    escala3: 'Hasta 1 escala',
    duration: 'Duración',
    aLa: 'a las',
    origenSearch: '¿Cuál es el origen?',
    noResultSearchFlight: 'No encontramos resultados para su búsqueda',
    hacia: 'Hacia',
    destinoSearch: '¿Hacia donde?',
    citySearch: 'Seleccione la ciudad',
    charter: 'Charter',
    flight: 'Vuelo',
    selectFlight: 'Seleccione el vuelo',
    clases: 'Clases',
    clase: 'Clase',
    cupo: 'Cupos',
    editCupos: 'Editar cupos y precios del {date}',
    cupoDisp: 'Cupos disponibles',
    price_adult: 'Precio Adulto',
    price_min: 'Precio más económico',
    releaseEstancia: 'Release y Estancia Mínima',
    releasePeriod: 'Periodo de Release',
    estanciaMin: 'Estancia Mínima',
    estanciaMax: 'Estancia Máxima',
    priceEstancia: 'Precios de Estancia',
    acomodation: 'Acomodación',
    ocupationMax: 'Ocupación máxima',
    ofertSpecial: 'Ofertas especiales',
    saleAnticip: 'Compra anticipada (EBB)',
    minPax: 'Min paxs',
    maxAdl: 'Max adl',
    maxMnr: 'Max mnr',
    maxPax: 'Max paxs',
    adl: 'Adl',
    adulto: 'Adulto',
    adultos: 'Adultos',
    childs: 'Niños',
    child: 'Niño',
    childsFlight: 'de 2 a 12',
    infants: 'Infantes',
    infantsFlight: 'menos de 2',
    mnr: 'Mnr',
    menor: 'Menor',
    menors: 'Menores',
    infat: 'Infante',
    rangeAge: 'Rango de edades',
    mayorQue: 'Mayor que',
    hasta: 'hasta',
    price_child: 'Precio Niños',
    price_infant: 'Precio Infante',
    infant_free: 'Infante Gratis',
    is_cabina: 'Cabina',
    is_bodega: 'Bodega',
    is_personal: 'Personal',
    local: 'Local',
    equipaje_personal: {
      title: 'Artículo personal',
      dimensiones: 'Dimensiones',
      peso: 'Peso',
      precio: 'Precio',
      para: 'Aplica para',
      adul: 'Adulto',
      child: 'Menor',
      inf: 'Infante',
    },
    equipaje_cabina: {
      title: 'Equipaje de mano',
      dimensiones: 'Dimensiones',
      peso: 'Peso',
      precio: 'Precio',
      para: 'Aplica para',
      adul: 'Adulto',
      child: 'Menor',
      inf: 'Infante',
      eq: 'Equipaje de',
      eqB: 'Mano',
    },
    equipaje_bodega: {
      title: 'Equipaje en bodega',
      dimensiones: 'Dimensiones',
      addMaleta: 'Adicionar Maleta',
      peso: 'Peso',
      precioMaleta: 'Maleta',
      max_exceso_equipaje: 'Peso máximo',
      exceso_equipaje: 'Exceso de Equipaje',
      addPeso: 'Adicionar Peso',
      tasa_despacho: 'Tasa despacho',
      para: 'Aplica para',
      adul: 'Adulto',
      child: 'Menor',
      inf: 'Infante',
      eq: 'Equipaje en',
      eqB: 'Bodega',
    },
    maleta_adicional: {
      title: 'Maleta adicional',
      dimensiones: 'Dimensiones',
      peso: 'Peso',
      cant: 'Cant.',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    precio_adicional: {
      title: 'Precio adicional',
      um: 'Unidad medida',
      de: 'de',
      a: 'a',
      precio: 'Precio',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    comidas_bordo: {
      title: 'Comidas a bordo',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    reembolso: {
      title: 'Reembolsos',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    cambio_fecha: {
      title: 'Cambios de fecha',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    elec_asiento: {
      title: 'Elección de asientos',
      para: 'Aplica para',
      adul: 'Adul.',
      child: 'Men.',
      inf: 'Inf.',
    },
    frecuency: 'Frecuencia',
    frecuencias: 'Frecuencias',
    frecuenciasFlight: 'Frecuencias del vuelo',
    temporadas: 'Temporadas',
    fromSeason: 'Inicio de temporada',
    toSeason: 'Fin de temporada',
    validez: 'Validez',
    vigencia: 'Vigencia',
    fromValidez: 'Validez desde',
    toValidez: 'Validez hasta',
    dimension: 'Dimensión',
    largo: 'Largo',
    alto: 'Alto',
    ancho: 'Ancho',
    tipo: 'Tipo',
    lineal: 'Lineal',
    reference: 'Referencia',
    um: 'UM',
    peso: 'Peso',
    pesoMax: 'Max.',
    fechasVuelo: 'Fechas de vuelo',
    todosDay: 'Todos',
    todosDays: 'Todos los días',
    semanaDay: 'Días de la semana',
    especifDay: 'Días específicos',
    detailContrate: 'Condiciones del Contrato',
    infoContrate: 'Información del Contrato',
    tarif: 'Tarifa',
    tarifa: 'Tarifas',
    rangeDaysRent: 'Rango de Días de Renta',
    conditionContrate: 'Política Tarifaria',
    rulesTarifa: 'Reglas de la tarifa',
    otherTarifa: 'Otras tarifas',
    date: 'Fecha',
    dateCreate: 'Fecha de creación',
    dateRegistro: 'Fecha de registro',
    car: 'Auto',
    cantPuertas: 'Cantidad de puertas',
    cantPaxs: 'Cantidad de paxs',
    cantMaletas: 'Maletas',
    small: 'Pequeñas',
    big: 'Grandes',
    typeCar: 'Coche tipo',
    travels: 'Viajeros',
    doors: 'Puertas',
    umComb: 'UM',
    capacityComb: 'Capacidad de combustible',
    consumoComb: 'Consumo de combustible',
    motor: 'Motor',
    only_adult: 'Sólo adultos',
    publico: 'Publicado',
    stopSale: 'Paro de venta',
    sinPublicar: 'Sin Publicar',
    modality: 'Modalidad',
    sugerido: 'Sugerido',
    orderSugerido: 'Prioridad',
    infoSugerido:
      'Defina el orden para mostrar el producto en varias secciones, páginas de disponibilidad y búsquedas.',
    sinSugerir: 'Sin Sugerir',
    searchImages: 'Busque las imágenes',
    searchVideos: 'Busque los videos',
    addImages: 'Agregar imágenes',
    galery: 'Galería',
    details: 'Detalles',
    edadConductor: 'Edad del Conductor',
    proveedor: 'Proveedor',
    acredor: 'Acreedor',
    deudor: 'Deudor',
    cadena: 'Cadena',
    aerolinea: 'Aerolínea',
    validFrom: 'Válido desde',
    validTo: 'Válido hasta',
    change: 'Cambios',
    dataOld: 'Datos antiguos',
    propuesta: 'Propuesta de Cambios',
    oficina: 'Oficina',
    time_completo: 'Abierto 24 horas',
    bag: 'Equipaje',
    bagNot: 'No disponible',
    bagNoInclude: 'Equipaje no incluido en el precio',
    pieza: '{n} pieza | {n} piezas',
    esca: '{n} escala | {n} escalas',
    bagHand: 'Equipaje de mano',
    filtros: 'Filtros',
    hotelsFilters: 'hoteles de',
    clearFilters: 'Borrar filtros',
    nameAlojamientoFilters: 'Nombre del alojamiento',
    categoryFilters: 'Categoría',
    priceFilters: 'Precio',
    typeDestinyFilters: 'Tipo de destino',
    cadenaFilters: 'Cadena hotelera',
    marcaFilters: 'Marca hotelera',
    typeReserveFilters: 'Tipo de reserva',
    contrateFilters: 'Contratos',
    servicesFilters: 'Servicios',
    interesesFilters: 'Intereses',
    recomendadosFilters: 'Recomendados',
    entry: 'Entrada',
    exit: 'Salida',
    orderByFilters: 'Ordenar por',
    showMoreFilters: 'Ver más',
    showLessFilters: 'Ver menos',
    byMejorOpcion: 'Mejor opción',
    byMasEconomico: 'Más económicos',
    byMasRapido: 'Más rápido',
    searchDestiny: 'Buscando destinos',
    prepararSelect: 'Preparar selección',
    borrarSelect: 'Borrar lista de vuelos preferidos',
    backSearch: 'Regresar a resultados de búsqueda',
    tarifaComplete: 'Tarifa de la ruta completa',
    pax: 'Pasajero',
    systemCode: 'Datos para generar sus reservas',
    siglas: 'Siglas',
    systemCodePrefijo: 'Prefijo para incluir en sus reservas',
    systemCodeProduct: 'Pueden ser una letras o un número',
    programationFlights: 'Programación de vuelos',
    client: 'Cliente',
    product: 'Producto',
    compra: 'Compra',
    service: 'Servicio',
    venta: 'Venta',
    afiliado: 'Afiliado',
    proximas: 'Próximas',
    actives: 'Activas',
    sendEmail: 'Enviar por email',
    or: 'o',
    in: 'en',
    downloadPdf: 'Descargar en PDF',
    hourario: 'Horario',
    calculo: 'Cálculo',
    settingGeneral: 'Configuración general',
    settingBot: 'Bot',
    settingUser: 'Usuario',
    titleChat: 'Título del chat',
    colorTheme: 'Color del tema del chat',
    bgMessage: 'Fondo del mensaje',
    colorMessage: 'Color del texto',
    avatarSize: 'Tamaño avatar',
    inputPlaceholder: 'Placeholder del input',
    order: 'Orden',
    message: 'Mensaje',
    options: 'Opciones',
    optionTitle: 'Título Opción',
    selectTalking: 'Seleccione una conversación',
    talkingArchive: 'Conversaciones archivadas',
    companyConfiguration: 'Compañía',
    isCompany: 'Es una Compañía',
    dataCompany: 'Datos de su Compañía',
    viewReserve: 'Ver las reservaciones',
    nameViajero: 'Nombre del cliente',
    codeReserva: 'Código de la reserva',
    codeCotization: 'Código de la cotización',
    stateReserva: 'Estado de la reserva',
    stateAccount: 'Estado de la cuenta',
    stateCotization: 'Estado de la cotización',
    afiliate: 'Afiliado',
    afiliateAgency: 'Agencia afiliada',
    vendedor: 'Vendedor',
    nameAPI: 'Identificador para sus Tokens',
    account: 'Cuenta',
    security: 'Seguridad',
    edad: 'Edad',
    menorDe: 'Menor de',
    mayorDe: 'Mayor de',
    noReembolsable: 'No reembolsable',
    permiteCambios: 'Permite cambios',
    cambiosView: 'Vea más detalles sobre cambios y cancelaciones en el próximo paso.',
    equipaje: 'Equipaje',
    contacts: 'Contactos',
    contact: 'Contacto',
    incluida: 'Incluida',
    permitida: 'Permitida',
    noPermitida: 'No Permitida',
    sobrepeso: 'Sobrepeso',
    sobreDimension: 'Sobredimensión',
    dimensionMax: 'Dimensión Máxima',
    action: 'Acción',
    rental: 'Rentadora',
    comision: 'Contrato comisionable',
    typeContrato: 'Tipo de Contrato',
    contrato: 'Contrato',
    typeReserve: 'Tipo de Reserva',
    modelPrice: 'Modelo de precio',
    firstDay: 'Primer día',
    dayByDay: 'Día x día',
    modalidad: 'Modalidad',
    suplemento: 'Suplemento',
    reduccion: 'Reducción',
    periodoValidez: 'Periodo de Validez',
    cat: 'Categoría',
    others: 'Otros',
    other: 'Otro',
    brandModel: 'Marca y Modelo',
    seguro: 'Seguro',
    deposito: 'Depósito',
    depositoGarantia: 'Depósito de garantía',
    tarifaRetorno: 'Tarifa de retorno',
    garantia: 'Garantía',
    dayExtra: 'Día extra',
    onRequest: 'Con confirmación',
    freeSale: 'Reserva inmediata',
    recogida: 'Recogida',
    dateRecogida: 'Fecha Recogida',
    entrega: 'Entrega',
    dateEntrega: 'Fecha Entrega',
    recogidaEntrega: 'Recogida y Entrega',
    entregaDiffRecogida: 'Entregar en diferente lugar',
    gestionarOficinas: 'Gestionar oficinas de renta',
    addOficinas: 'Agregar oficinas de renta',
    filterOficinas: 'Filtrar oficinas de renta',
    checkAll: 'Todas',
    addOficineRentadora: 'Agregar oficinas a la rentadora',
    rentaAplicaCuba: 'Todos los días de renta con el precio del primer día',
    detallesReserva: 'Detalles de la reserva',
    conductorPrincipal: 'Conductor principal',
    conductorAditional: 'Conductor adicional',
    byNight: 'Por noche',
    byRoom: 'Por hab',
    byRoomNight: 'Por hab/noche',
    byPax: 'Por pax',
    byPaxNight: 'Por pax/noche',
    byDay: 'Por día',
    byKm: 'Por km',
    bySerice: 'Por servicio',
    byUnidad: 'Por unidad',
    byPeso: 'Por peso',
    optional: 'Opcional',
    obligatorio: 'Obligatorio',
    infoImportant: 'Información importante',
    termsConditions: 'Términos y condiciones',
    policesCancele: 'Políticas de cancelación y no presentación',
    docConductor: 'Documentación requerida para el conductor',
    policesComb: 'Políticas de combustible',
    intoProvince: 'Dentro de la provincia',
    pto: 'Punto',
    passport: 'Número de pasaporte',
    docIdentidad: 'Doc. identidad',
    daysRent: 'Días de renta',
    orSimilar: 'o similar',
    readTermsConditions: 'He leído y acepto todas las políticas, términos y condiciones que se detallan a continuación',
    observations: 'Observaciones del Contrato (No visible al cliente)',
    observation: 'Observación',
    hotels: 'Hoteles',
    hotelsFilter: 'Hoteles Filtrados',
    hotelsAsociado: 'Hoteles Asociados',
    asociarHotels: 'Asociar Hoteles',
    addHotels: 'Agregar Hoteles',
    gestionCodes: 'Gestionar Códigos para {item}',
    gestionCode: 'Gestionar Códigos',
    noHotelsAsociados: 'No hay hoteles para asociar',
    hotelsAsociados: 'No hay hoteles asociados',
    asociateRoom: 'Asociar habitación',
    room: 'Habitación',
    rooms: 'Habitaciones',
    and: 'y',
    regimen: 'Régimen',
    plan: 'Plan',
    uso: 'Uso',
    noSeasonHotel: 'No existen temporadas para el hotel {item}',
    required: 'Requerido',
    onlyNumber: 'Solo números',
    cantBars: 'Cant. bares',
    cantRoom: 'Cant. hab.',
    cantRest: 'Cant. restaurantes',
    cantCafeterias: 'Cant. cafeterías',
    cantSalon: 'Cant. salón',
    cantPiscinas: 'Cant. piscinas',
    hotelDestinos: 'Ciudad, región o nombre de alojamiento',
    dateIn: 'Fecha de entrada',
    fromAl: 'desde las',
    dateOut: 'Fecha de salida',
    dateSale: 'Fecha de venta',
    toAl: 'hasta las',
    ocupation: 'Ocupación',
    roomD: 'hab.',
    edadChild: 'Edad de los niños en la fecha de viaje',
    cantChild: '{n} niño | {n} niños',
    years: 'años',
    age: 'edad',
    modeloPrice: 'Modelo de Precios',
    paxNight: 'pax/noche',
    roomNight: 'hab/noche',
    supl: 'Supl.',
    reduc: 'Reduc.',
    nightsFrom: '{n} noche desde | {n} noches desde',
    moreLeer: 'Leer más',
    moreSee: 'Ver más',
    lessLeer: 'Leer menos',
    lessSee: 'Ver menos',
    infoHuesp: 'Información de los huéspedes',
    huesp: 'Huésped',
    huespPrincipal: 'Huésped principal',
    addDataHuesp: 'Agregar datos de los demás huéspedes',
    deleteDataHuesp: 'Quitar los datos de los demás huéspedes',
    roomingList: 'Rooming list',
    dateReservation: 'Fecha de la reserva',
    reserveFrom: 'Reserva desde',
    reserveHome: 'Inicio de la reserva',
    reserveTo: 'Reserva hasta',
    reserveEnd: 'Fin de la reserva',
    dateCotization: 'Fecha de la cotización',
    cotizationFrom: 'Cotización desde',
    cotizationTo: 'Cotización hasta',
    dateHome: 'Fecha Inicio',
    dateService: 'Fecha del servicio',
    serviceFrom: 'Servicio desde',
    serviceTo: 'Servicio hasta',
    homeService: 'Inicio del servicio',
    endService: 'Fin del servicio',
    cargaLote: 'Carga por lotes',
    existHotel: 'Ya existe un hotel con este nombre',
    updateHotel: 'Este hotel se va a actualizar con los nuevos cambios, pues ya existe en la base de datos.',
    inexistOperador: 'Este operador no existe',
    problemsHotels: 'Por favor verifique que desea hacer con estos {items}.',
    problemsOperador: 'Problemas con los operadores subidos. Por favor verifíquelos.',
    inexistMarcaAsociada: 'Esta marca asociada no existe',
    problemsMarcaAsociada: 'Problemas con las marcas asociadas subidas. Por favor verifíquelas.',
    inexistCategoria: 'Esta categoría no existe',
    problemsCategoria: 'Problemas con las categorías subidas. Por favor verifíquelas.',
    inexistAge: 'Esta edad no existe',
    problemsAge: 'Problemas con las edades subidas. Por favor verifíquelas.',
    inexistTipoDestino: 'Este tipo de destino no existe',
    problemsTipoDestino: 'Problemas con los tipos de destinos subidos. Por favor verifíquelos.',
    inexistServicio: 'Este servicio no existe',
    problemsServicio: 'Problemas con los servicios subidos. Por favor verifíquelos.',
    problemsIntereses: 'Problemas con los intereses subidos. Por favor verifíquelos.',
    problemsNameHotel: 'Problemas con los nombres de hoteles subidos. Por favor verifíquelos.',
    problemsHabitaciones: 'Problemas con las habitaciones subidas. Por favor verifíquelas.',
    problemsPlanAlim: 'Problemas con los régimenes alimenticios subidos. Por favor verifíquelos.',
    problemsSuplement: 'Problemas con los suplementos subidos. Por favor verifíquelos.',
    problemsSuplementReduc: 'Problemas con los suplementos/reducciones subidos. Por favor verifíquelos.',
    problemsReducc: 'Problemas con las reducciones subidas. Por favor verifíquelas.',
    problemsPtoRecogida: 'Problemas con las los puntos de recogidas subidos. Por favor verifíquelos.',
    gestionChange: 'Gestionar cambios',
    services: 'Servicios',
    orderFileColumn: 'Orden de las columnas del archivo',
    descriptionCampos: 'Descripción de los campos',
    notModel: 'No existe modelo para ese proveedor. Contacte a soporte, por favor',
    hotel: 'Hotel',
    notPublic: 'sin Publicar',
    infoPendiente: 'con info Pendiente',
    sinOperador: 'sin Operador',
    sinMarcaAsociada: 'sin Marca Asociada',
    sinFotos: 'sin Fotos',
    sinTagFotos: 'sin Tag de Fotos',
    sinTagPrincipal: 'sin Tag Principal',
    sinDireccion: 'sin Dirección',
    sinLocalidas: 'sin Localidad',
    sinCrop: 'sin redimensionar',
    sinMarca: 'sin Marca',
    sinModelo: 'sin Modelo',
    sinTransmision: 'sin Transmisión',
    sinCarroceria: 'sin Carroceria',
    sinCombustible: 'sin Combustible',
    sinCantPuertas: 'sin Cant. Puertas',
    sinCantPaxs: 'sin Cant. Paxs',
    sinCantBagBig: 'sin Cant. Maletas Grandes',
    sinCantBagSmall: 'sin Cant. Maletas Pequeñas',
    sinUm: 'sin unidad de medida',
    sinCapacidad: 'sin Capacidad comb.',
    sinConsumoComb: 'sin Consumo comb.',
    sinMotor: 'sin Motor',
    sinCaracteristicas: 'sin Características',
    sinPrioridad: 'sin Prioridad',
    sinRooms: 'sin habitaciones',
    sinOcupation: 'sin ocupación',
    sinPlan: 'sin régimen',
    sinPrice: 'sin precios',
    sinContact: 'sin contactos',
    vencimiento: 'vencimiento',
    vencimiento3: 'en 3 meses',
    vencimiento6: 'en 6 meses',
    principal: 'Principal',
    problemsMarcas: 'Problemas con las marcas subidas. Por favor verifíquelas.',
    problemsModelos: 'Problemas con los modelos subidos. Por favor verifíquelos.',
    problemsTransmisiones: 'Problemas con las transmiciones subidas. Por favor verifíquelas.',
    problemsTipoAutos: 'Problemas con las carroserías subidas. Por favor verifíquelas.',
    problemsCombustible: 'Problemas con los combustibles subidos. Por favor verifíquelos.',
    problemsCaracteristicas: 'Problemas con las características subidas. Por favor verifíquelas.',
    problemsTags: 'Problemas con los tags subidos. Por favor verifíquelos.',
    cantImgUpload: 'Solo se subirán hasta {item} imágenes a la vez.',
    sizeVideoUpload: 'Solo se subirán hasta {item} videos a la vez.',
    execImgUpload: 'A excedido las {item} imágenes permitidas.',
    porciento: 'Porciento',
    travel: 'Viaje',
    reserve: 'Reserva',
    pay: 'Pago',
    descuent: 'Descuento',
    calculateBy: 'Calculado por',
    before: 'Antes de',
    estancia: 'Estancia',
    titleTipoContrate:
      'Debe especificar si el Contrato aplica por Categoría (varios vehículos similares) o por Marca y Modelo (se permite rentar un vehículo en específico)',
    titleComitionContrate: 'Se mantiene el precio público y se aplica la comisión otorgada por el proveedor.',
    titleModeloPriceContrate: 'Aplica para el cálculo de las tarifas y los cambios de temporadas.',
    allInclude: 'Todo incluido',
    markupsAfiliate: 'Markups para ventas de afiliados',
    markupsOwner: 'Markups para ventas propias',
    saleFisic: 'Ventas físicas',
    fisic: 'Físicas',
    saleOnline: 'Ventas on-line',
    modelPvp: 'Modelo PVP',
    comitionsOwner: 'Comisiones para ventas propias',
    comitionsAfiliate: 'Comisiones para ventas de afiliado',
    numerConfirmed: 'Número de confirmación',
    commentsGestor: 'Comentarios para el gestor',
    commentsClient: 'Comentarios para el cliente',
    comment: 'Comentario',
    timeGrace: 'Tiempo de gracia',
    createReserve: 'Se ha creado una nueva reserva, con código',
    createCotization1: 'Se ha creado una nueva cotización, con código',
    createCotization2: 'Tipo de servicio',
    createCotization3: 'Fecha del servicio',
    createCotization4: 'Usuario',
    incluyeDayGrace: 'incluye +1 día',
    incluyeDayGraceMsg:
      'Se agrega un día extra de renta, al servicio contratado, debido a la hora de entrega fuera del tiempo de gracia previsto en el contrato, con respecto al horario de recogida del auto.',
    cantRecipients: 'Total de destinatarios',
    recipients: 'Destinatarios',
    programeSend: 'Programar envío',
    zoneHour: 'Zona horaria',
    dateTime: 'Fecha y hora',
    content: 'Contenido',
    template: 'Plantilla',
    emailsReserve: 'Correos electrónicos de reservas',
    infoEmailsReserve:
      'Cuentas de correos para el envío automático de confirmaciones de reservas, vouchers, facturas, etc.',
    sendNotification: 'Enviar notificación',
    incluye: 'Incluye',
    clients: 'Clientes',
    title: 'Título',
    urlExtern: 'Url externa',
    uploadVideo: 'Subir video',
    changeVideo: 'Cambiar video',
    dates: 'Fechas',
    typeOffert: 'Tipo de oferta',
    offertSpetial: 'Oferta especial',
    offline: 'Offline',
    offlineMsg:
      'Si desactiva el Afiliado lo estaría desconectando del Sistema y no podrá consultar disponibilidad, generar ventas, etc',
    userOffline: 'Este contenido no está habilitado para Usted en estos momentos.',
    notChangesUpdate: 'Tiene cambios sin guardar. ¿ Está seguro que desea continuar?',
    payMethod: 'Método de pago',
    wayMethod: 'Forma de pago',
    waysMethod: 'Formas de pago',
    credit: 'Crédito',
    cardName: 'Nombre en la tarjeta',
    cardNumber: 'Número de tarjeta',
    cardExpire: 'Caducidad',
    transfer: 'Transferencia',
    transferBank: 'Transferencia bancaria',
    linkPay: 'Link de pago',
    cardCredit: 'Tarjeta de crédito',
    payCard: 'Pago con tarjeta',
    checkBank: 'Cheque bancario',
    cash: 'Efectivo',
    cobradoPor: 'Cobrado por',
    caducada: 'Caducada',
    vigente: 'Vigente',
    time: 'Tiempo',
    balances: 'Balances',
    balance: 'Balance',
    limitCredit: 'Límite de crédito',
    creditRest: 'Crédito disponible',
    deuda: 'Deuda',
    limitTime: 'Límite de tiempo',
    timeAvailable: 'Tiempo disponible',
    fondoRest: 'Saldo a favor',
    payFondoRest: 'Pagar con saldo a favor',
    deposit: 'Depósitos',
    credits: 'Créditos',
    operaCont: 'Operaciones contables',
    compPay: 'Comprobantes de pago',
    dateOperation: 'Fecha de la operación',
    dateCreation: 'Fecha de creación',
    createBy: 'Creado por',
    cantidad: 'Cantidad',
    typeOperation: 'Tipo de operación',
    typeDeposit: 'Tipo de depósito',
    totalDeposit: 'Total del depósito',
    dateDeposit: 'Fecha del depósito',
    depositBy: 'Depositado por',
    excecuteBy: 'Realizada por',
    use: 'Uso',
    observaciones: 'Observaciones',
    addBy: 'Adicionado por',
    dateAddSistem: 'Fecha  de adición al sistema',
    incrementCredict: '¿Incrementa límite de crédito actual?',
    introducBySistem: 'Introducido en el sistema por',
    dateIntroducBySistem: 'Fecha de introducción en el sistema',
    countAvailableBefore: 'Cantidad disponible antes de la operación',
    countAvailableAfter: 'Cantidad disponible después de la operación',
    dowloadExample: 'CSV de ejemplo',
    fileDowloadExample: '{ext} de ejemplo',
    titlePrintCar: 'Seleccione la cotización que desea imprimir',
    titleSendCar: 'Seleccione la cotización que desea enviar',
    printCar1: 'Solo el producto actual',
    printCar2: 'Incluir los productos que están en el carro de compra',
    para: 'Para',
    subject: 'Asunto',
    adjuntCotization: 'Cotización adjunta',
    sendCotization: 'Enviar cotización',
    printCotization: 'Imprimir cotización',
    downloadCotization: 'Descargar cotización',
    paquet: 'Paquete',
    agency: 'Agencia',
    pagado: 'Pagado',
    pendiente: 'Pendiente',
    transactions: 'Transacciones',
    documents: 'Documentos',
    history: 'Historial',
    localizador: 'Localizador',
    applyTo: 'Aplica a',
    importe: 'Importe',
    carShop: 'Carro de compras',
    payDestino: 'A pagar en el destino',
    payOther: 'Otros cargos a pagar',
    paymentOther: 'Otros cargos',
    toAeroline: 'a la aerolínea',
    toRent: 'a la rentadora',
    toAlojamient: 'al alojamiento',
    ctaXCobrar: 'CTA x cobrar',
    cobrado: 'Cobrado',
    ctaXPagar: 'CTA x pagar',
    drivers: 'Conductores',
    document: 'Documento',
    payToRent: 'a pagar en la rentadora',
    rentToCars: 'Renta de autos',
    rentToHotels: 'Alojamiento',
    withSecureInclude: 'c/ Seguro incluido',
    principate: 'principal',
    adicional: 'adicional',
    suplente: 'suplente',
    andSuplente: 'y suplente',
    ilimit: 'ilimitados',
    backCarShop: 'Regresar al carro de compras',
    backReserve: 'Regresar a la reserva',
    cotization: 'Cotización',
    sameConductor: 'Mismo conductor para todos los servicios',
    infoPendient: 'Información Pendiente',
    completeInfo: 'Completar Información',
    hidden: 'Ocultar',
    show: 'Mostrar',
    airplaneStruct: 'Estructura de la aeronave',
    payEvidency: 'Evidencia de pago',
    evidency: 'Evidencia',
    formatPay: 'Formatos aceptados: {formats}',
    saldo: 'Saldo',
    map: 'Mapa',
    ajusteRequered: 'Ajuste requerido',
    reembolsoRequered: 'Reembolso requerido',
    reembolsoTotal: 'Total',
    emitidoBy: 'Emitido por',
    emitidoFrom: 'Emitido desde',
    emitidoTo: 'Emitido hasta',
    dateEmitido: 'Fecha de emisión',
    provideService: 'Proveedor del servicio',
    importePay: 'Importe pagado',
    verify: 'Verificación',
    recibidoBy: 'Recibido por',
    recibidoFrom: 'Recibido desde',
    recibidoTo: 'Recibido hasta',
    dateRecibido: 'Fecha de recepción',
    deudorAfiliate: 'Agencia, agente o cliente',
    statusPay: 'Estado del pago',
    cobradoBy: 'Cobrado por',
    cobradoFrom: 'Cobrado desde',
    cobradoTo: 'Cobrado hasta',
    dateCobrado: 'Fecha de cobro',
    gestorAcreditoPay: 'Gestor que acreditó el pago',
    reclamated: 'Reclamación',
    cancelated: 'Cancelación',
    table: 'Tabla',
    tableData: 'Tabla de la base de datos',
    inputs: 'Campos',
    inputsExport: 'Campos a exportar',
    selectAll: 'Seleccionar todos',
    notShowElement: 'No existen elementos a mostrar',
    videosUrl: 'Videos desde url externa',
    about: '¿Quiénes somos?',
    geolocalizar: 'Geolocalizar casa matriz',
    merchantCode: 'Código de comerciante',
    merchantTerminal: 'Terminal',
    merchantTransactionId: 'Id de la Transacción',
    currency: 'Moneda',
    currencies: 'Monedas',
    clientCorporate: 'Cliente Corporativo',
    dataNotDisponse: 'Datos no disponibles',
    infoFiscal: 'Info Fiscal',
    bancaria: 'Bancaria',
    credito: 'Crédito',
    ejecutivos: 'Ejecutivos',
    line: 'línea',
    payMedio: 'Medios de pago',
    phoneUSA: 'Celular de USA',
    valueUSD: 'Valor (USD)',
    dateUpdate: 'Fecha actualización',
    updated: 'Actualizado',
    updatedBy: 'Actualizado por',
    changeCurrency: 'Cambio de moneda',
    timeUTC: 'Time Standard (UTC)',
    timeUTCPublic: 'Hora Publicación T/C',
    timeUTCCheck: 'Hora Chequeo T/C',
    timeUTCActive: 'Hora Activación T/C',
    afiliateCorporate: 'Afiliado o Corporativo',
    offertsWith: 'oferta con {cant} proveedores',
    nights: 'noches',
    selectCurrency: 'Seleccione la moneda',
    typeAuto: 'Asegúrate de contar con una API activa para la conversión automática de monedas.',
    season: 'Temporada',
    dateFrom: 'Fecha desde',
    dateTo: 'Fecha hasta',
    destinoUOfic: 'Destino u Oficina de renta',
    operadors: 'Operadores',
    suplementsCost: 'Suplementos y costos asociados',
    docRequired: 'Documentación requerida',
    notes: 'Notas',
    roomsPlan: 'Habitaciones, plan de alimentos y edades',
    adolescentes: 'Adolescentes',
    estanciaDbl: 'Estancia en ocupación doble',
    suplRedTarif: 'Suplementos, Reducciones y Tarifas de alojamiento',
    polityChild: 'Política de niños',
    suplementsAlimenticios: 'Suplementos y Tarifas para plan de alimentos',
    suplementsOtros: 'Otros suplementos',
    cancelaciones: 'Cancelaciones y no Show',
    denegarAfil: 'Denegar solicitud de afiliación',
    denegarMotive: 'Motivo',
    denegarMotiveInfo: 'Motivo de la denegación',
    exchangeRate: 'Tipo de cambio',
    neto: 'Neto',
    codeClient: 'Código del cliente',
    addTarifa: 'Adicionar tarifa',
    deleteTarifa: 'Eliminar tarifa',
  },
  btn: {
    asociate: 'Asociar',
    desvincule: 'Desvincular',
    send: 'Enviar',
    select: 'Seleccionar',
    apply: 'Aplicar',
    add: 'Adicionar',
    save: 'Guardar',
    descartar: 'Descartar',
    continue: 'Continuar',
    search: 'Buscar',
    close: 'Cerrar',
    cancele: 'Cancelar',
    insert: 'Insertar',
    generate: 'Generar',
    regenerate: 'Regenerar',
    create: 'Crear',
    copy: 'Copiar',
    update: 'Actualizar',
    updatePermisos: 'Actualizar Permisos',
    edit: 'Editar',
    delete: 'Eliminar',
    clone: 'Clonar',
    programation: 'Programación',
    see: 'Ver',
    back: 'Regresar',
    import: 'Importar',
    export: 'Exportar',
    activar: 'Activar',
    desactivar: 'Desactivar',
    remplace: 'Reemplazar',
    all: 'Todos',
    acceptChange: 'Aceptar Cambios',
    canceleChange: 'Rechazar Cambios',
    uploadLote: 'Subir por lotes',
    importFiles: 'Importar archivos',
    restaurarLote: 'Restaurar datos',
    restaurar: 'Restaurar',
    canceleUpload: 'Cancelar carga',
    reserveNow: 'Reservar ahora',
    reserve: 'Reservar',
    archive: 'Archivar',
    next: 'Siguiente',
    preview: 'Anterior',
    complete: 'Completar',
    resize: 'Recortar',
    print: 'Imprimir',
    sendEmail: 'Enviar por correo',
    clear: 'Limpiar',
    addToCar: 'Agregar al carro',
    printCotization: 'Imprimir cotización',
    sendCotization: 'Enviar cotización',
    editCotization: 'Editar cotización',
    soliciteReserve: 'Solicitar reserva',
    pay: 'Pagar',
    cobrar: 'Cobrar',
    payReserve: 'Pagar la reserva',
    cobrarReserve: 'Cobrar la reserva',
    share: 'Compartir',
    dowload: 'Descargar',
    gestion: 'Gestionar',
    emitPays: 'Emitir Pagos',
    sendPays: 'Enviar Pagos',
    printPays: 'Imprimir Pagos',
    gestionarCobros: 'Gestionar Cobros',
    gestionarProveedores: 'Gestionar Proveedores',
    sincronizar: 'Sincronizar',
    yes: 'Sí',
    no: 'No',
  },
  msg: {
    dataSync: 'Datos sincronizándose',
    dataRequiere: 'Debe introducir sus datos.',
    dataSpecific: 'Debe especificar un rango de fechas.',
    infoSuccess: 'Información actualizada.',
    sendEmails: 'Se ha enviado su mensaje.',
    fileUpload: 'Archivo en proceso.',
    invalidCredentials: 'Credenciales inválidas.',
    userInactive: 'Usuario inactivo',
    datasRequiere: 'Debe llenar los datos requeridos.',
    requiereAprovation: 'Su solicitud será atendida en breve y se le comunicará.',
    noAutorice: 'No está autorizado a realizar esta operación.',
    notMatch: 'No coinciden las contraseñas.',
    deleteMessage: '¿Está seguro que desea eliminar este mensaje?',
    deleteItem: '¿Está seguro que desea eliminar {item}?',
    sendNotificationUser: 'Se le enviará otro correo de invitación al usuario {item}.',
    deleteSuplement: '¿Está seguro que desea eliminar el suplemento: {item}?',
    deleteImg: '¿Está seguro que desea eliminar la imagen?',
    deleteVideo: '¿Está seguro que desea eliminar el video?',
    archiveChat: '¿Está seguro que desea archivar este chat?',
    deleteBookingItem: '¿Va a cancelar la reserva {item}?',
    deleteUndo: 'Este proceso es irreversible.',
    deleteService1: 'Se va a proceder con la Cancelación del servicio {item}.',
    deleteService2: 'Esta acción será irreversible y no se aplicará Reembolso al cliente.',
    deleteService3: 'Esta acción será irreversible y se aplicará Reembolso al cliente.',
    deleteService4: 'Tipo de reembolso',
    deleteService5: 'Cantidad a Reembolsar',
    canceleService: '¿Desea cancelar el servicio {code}?',
    convertToReserve: '¿Está seguro que desea convertir la cotización {item} a reserva?',
    deleteMarkups: '¿Está seguro que desea eliminar este Markups?',
    deleteStopSale: '¿Está seguro que desea eliminar este Paro de Ventas?',
    deleteOfertEBB: '¿Está seguro que desea eliminar esta Oferta?',
    deleteFrecuencia: '¿Está seguro que desea eliminar la frecuencia {item}?',
    deleteContrate: '¿Está seguro que desea eliminar este contrato?',
    deleteFlight: '¿Está seguro que desea eliminar el vuelo {item}?',
    deleteCar: '¿Está seguro que desea eliminar el auto {item}?',
    deleteHotel: 'Se va a eliminar el Hotel {item}. Esta acción será irreversible.',
    deleteServicio: '¿Está seguro que desea eliminar este servicio {item}?',
    errorDeleteFlight: 'No es posible eliminar el vuelo {item}. Existen contratos relacionados a este.',
    errorDeleteCar: 'No es posible eliminar el auto {item}. Existen contratos relacionados a este.',
    errorDeleteSeasonHotel: 'No es posible eliminar esta temporada. Existen precios relacionados a esta.',
    errorDeleteHotel: 'No es posible eliminar el hotel {item}. Existen contratos relacionados a este.',
    deleteSeasonHotel: '¿ Está seguro que desea eliminar la temporada {item}?',
    selectFlight: 'Upsss!!!... debe seleccionar un vuelo para continuar.',
    noNumberFlight: 'Upsss!!!... no se encuentra el vuelo {numero}.',
    dupliqueFrecuencia: 'Revice sus datos, está duplicando tarifas al contrato.',
    userExist: 'Ya existe un usuario con el email {email}',
    airlineExist: 'Ya existe una aerolínea con el identificador {identificador}',
    itemExist: 'Ya existe un item con el nombre {identificador}',
    airplaneExist: 'Ya existe una aeronave con el nombre {identificador}',
    roomExist: 'Ya existe una habitación con el nombre {identificador}',
    airplaneEdad: 'Ya existe la edad {identificador}',
    airportExist: 'Ya existe el aeropuerto {identificador}',
    claseExist: 'Ya existe una clase con el nombre {identificador}',
    tarifaVueloExist: 'Ya existe una tarifa con el nombre {identificador}',
    dimensionExist: 'Ya existe una dimensión del tipo {identificador}',
    pesoExist: 'Ya existe un peso del tipo {identificador}',
    transmisionExist: 'Ya existe la transmisión {identificador}',
    typeAutoExist: 'Ya existe el tipo de auto {identificador}',
    typeAutoNote: 'Los tipos o clasificaciones del auto corresponden a la carrocería automotriz.',
    marcaExist: 'Ya existe la marca {identificador}',
    modeloExist: 'Ya existe el modelo {identificador}',
    combustibleExist: 'Ya existe el combustible {identificador}',
    caracteristicaExist: 'Ya existe la característica {identificador}',
    categoryExist: 'Ya existe la categoría {identificador}',
    roleExist: 'Ya existe el rol {identificador}',
    rentadoraExist: 'Ya existe la rentadora {identificador}',
    tarifaExist: 'Ya existe la tarifa {identificador}',
    tarifaMaxExist: 'Si dejamos en blanco el máximo de días se tomará como ilimitado, ej: +{identificador}',
    edadMaxExist: 'Si dejamos en blanco el máximo de edad se tomará como ilimitado, ej: +{identificador}',
    sendEmail: 'Se ha enviado notificación a {email}',
    sendEmailAgente:
      'Se creo el Agente de ventas: {name}. Hemos enviado un email al responsable de esta cuenta para que confirme el registro y agregue su contraseña.',
    existFlight:
      'Upsss!!!... ya existe un vuelo registrado con el número {numero}. Por favor, verifique que haya introducido bien los datos. Si fuera necesario, vaya al listado de vuelos disponibles para editarlo.',
    existCars:
      'Upsss!!!... ya existe un auto registrado con el nombre {name}. Por favor, verifique que haya introducido bien los datos. Si fuera necesario, vaya al listado de autos para editarlo.',
    existProduct: 'El producto {name} ya existe en el Sistema. Pruebe cambiar su nombre.',
    existContrate:
      'Upsss!!!... ya existe un contrato con el identificador {identificador}. Por favor, verifique que haya introducido bien los datos. Si fuera necesario, vaya al listado de contratos para editarlo.',
    problemWithPay:
      'Upsss!!!... existe algún problema con su cuenta de {payment}. Por favor, verifique que haya introducido bien los datos y que tenga saldo suficiente para ejecutar la operación.',
    operadorExist: 'Ya existe el Operador {identificador}',
    marcaAsociadaExist: 'Ya existe la Marca Asociada {identificador}',

    confirmChange: 'Para que sus cambios tengan efecto necesita volver a realizar inicar sesión. ¿Desea continuar?',
    emptySeason: 'Upsss!!!... debe definir al menos una temporada.',
    activeUser:
      '¿Está seguro que desea activar el usuario {email}? Esta acción notificará al usuario para que cree su perfil.',
    desactiveUser:
      '¿Está seguro que desea desactivar el usuario {email}? Esta acción no permitirá que entre a la plataforma hasta tanto no se vuelva a activar.',

    noElementShow: 'No se encontraron elementos.',
    acceptChange: '¿Está seguro que desea aceptar los nuevos cambios?',
    rejectChange: '¿Está seguro que desea rechazar los nuevos cambios?',

    verifyTarifa: 'Consulta las reglas de la tarifa para saber si se admiten cambios.',
    verifyReembolso: 'Consulta las reglas de la tarifa para conocer la política de reembolso.',
    noResultFlightSearch: 'Upsss!!!... no se encontró resultados para su criterio de búsqueda.',

    copyText: 'Se ha copiado {text}',
    noAsociedCompany: 'No se encontró una empresa asociada a este usuario.',
    successToken: 'Tokens generados con éxito.',
    generateToken:
      '¿ Está seguro que desea resetear sus token? Esta acción romperá cualquier vínculo de aplicaciones externas que hagan uso de sus token actuales.',
    activeToken:
      'Esta acción permitirá la creación de un vínculo de aplicaciones externas haciendo uso de sus token actuales.',
    deleteToken: 'Esta acción romperá cualquier vínculo de aplicaciones externas que hagan uso de sus token actuales.',
    apiText1:
      'La API permite la comunicación e integración del B2C y terceros con los productos y servicios del B2B. Identifican su proyecto (la APP o el sitio web) que realiza la llamada a la propia API. Los tokens de autenticación identifican al usuario (la persona) que usa la APP o el sitio web.',
    apiText2:
      'El uso de esta API requiere autenticación y autorización (Secret Token) por lo que resulta necesario obtener las credenciales provistas aquí de manera automática a través de la solicitud de un Token de Acceso.',
    apiText3:
      'Es muy importante que sepas que quien tenga tu API Key tendrá acceso a tu cuenta, por lo que tienes que ser extremadamente cuidadoso con ella.',
    apiTextNoItems: 'No hay Compañías conectadas a la API',

    noResultFlightCotizacionTitle: 'Cambiar parámetros de búsqueda',
    noResultFlightCotizacionBody: 'Prueba a buscar vuelos en otras fechas o de diferentes aeropuertos',
    noResultCarCotizacionBody: 'Prueba a buscar autos en otras fechas o de diferentes destinos',
    noResultHotelCotizacionBody: 'Prueba a buscar hospedaje en otras fechas o de diferentes destinos',
    noResultTarifariosBody: 'Prueba a buscar en otras fechas',
    markupsNoValid: 'Lo sentimos no es posible aplicar los valores establecidos',

    profileChangeEmailTitle: 'Su correo electrónico no está confirmado. Por favor revise su bandeja de entrada.',

    passwordNotMath: 'Su contraseña actual no coincide.',

    bagInclude: 'Mochila o bolso inluido',
    bagNoInclude: 'Mochila o bolso no inluido',
    bagDescription: 'Debe caber bajo el asiento delantero',

    cabinaInclude: 'Equipaje de mano inluido',
    cabinaNoInclude: 'Equipaje de mano no inluido',
    cabinaDescription: 'Debe caber dentro del compartimento de cabina',

    bodegaInclude: 'Equipaje facturado inluido',
    bodegaNoInclude: 'Equipaje facturado no inluido',
    bodegaDescription: 'Se despacha durante el Check-in en el aeropuerto',
    bags: '{n} maleta por adulto | {n} maletas por adulto',
    horarioAirpor: 'Todos los horarios se muestran en la hora de cada aeropuerto.',
    flightCon: 'Tu vuelo con',
    tarifasMulti: 'Tarifas Múltiples',
    agreguePesoMax1: 'Agregue un valor de peso máximo para activar el sobrepeso permitido.',
    agreguePesoMax2: 'Si no se agrega un valor de peso máximo, la política de equipaje no admite sobrepeso.',
    equipajeSupere: 'Equipaje que supere el peso máximo se enviará como carga.',

    hourAirport: 'Todas las horas están dadas en el huso horario del aeropuerto local.',
    durationHourMin: 'Duración del vuelo, en formato de 24hrs (hh:mm).',
    durationTravelHourMin: 'Duración del viaje, en formato de 24hrs (hh:mm).',
    durationEscHourMin: 'Duración de la escala en formato de 24hrs (hh:mm).',
    timeFly: 'Tiempo de Viaje',

    notDeleteItem: 'No es posible eliminar este item porque tiene relaciones vigentes con otras entidades',
    deleteCategorias: 'Al aceptar esta acción se eliminarán todas las categorías y precios del contrato.',
    deleteModels: 'Al aceptar esta acción se eliminarán todos los modelos de autos y precios del contrato.',

    desvinculeRooms: 'Al aceptar esta acción se eliminarán todos precios de esta habitación en el contrato.',
    verifiqueInfo: 'Por favor, verifique toda la información antes de introducirlo a la base de datos.',
    errorHotel: 'Existen inconsistencias en el hotel {item}. Revizar el modelo cerca de "{element}".',
    errorInput:
      'Existen inconsistencias en el archivo "{file}" en la hoja "{sheet}". Se esperaba en la columna "{colum}" el valor "{item}" y viene "{element}".',
    canceleUpload: '¿ Está seguro que desea cancelar la carga por lotes de {item}?',
    processUpload: '¿ Está seguro que desea procesar toda esta información de {item}?',
    processUpload1:
      'La Carga por Lotes supone el reemplazo de la información  existente. Podrá regresar al estado anterior y restaurar a los valores iniciales en caso de que ocurran errores no deseados en el proceso de carga.',
    processUpload2:
      'Considere también que los productos afectados se guardarán Sin Publicar y estos no se mostrarán en la disponibilidad hasta que no se les cambie su estado.',
    restoreUpload1: 'La Carga por Lotes será restaurada a sus valores iniciales y se desestimará el útlimo cambio.',
    addDelete: '¿Está seguro que desea eliminar {item}?',
    addChange: '¿Está seguro que desea agregar {item}?',
    addSave: '¿Está seguro que desea guardar {item}?',
    addRemplace: '¿Está seguro que desea remplazar a {item} por {remplace}?',
    addAsociate: '¿Está seguro que desea asociar a {item} este valor: {asociate}?',
    loteContrateHotel:
      'Seleccione el proveedor del contrato para mostrar el modelo de datos correspondientes al archivo .csv que está permitido subir. Para la carga masiva, de la información de los contratos de hoteles, verificamos que el archivo .csv seleccionado cumpla con la estructura de datos previamente definida para cada proveedor. En caso de inconsistencias en el modelo de datos del archivo .csv seleccionado se abortará la carga masiva de la información del contrato de alojamiento hotelero para el proveedor seleccionado.',
    provedorNotConditions: 'El proveedor seleccionado no tiene condiciones generales asignadas',
    notDeleteItemRelations:
      'Upsss!!!... no es posible eliminar este item porque tiene relaciones vigentes con {entity}.',
    verifyEstanciaMin: 'Verifique que las estancias mínimas sean mayor a 1',
    edadDriver1:
      'Este auto sólo está disponible para conductores con edad: +{edad} años. Por favor, verifique la edad introducida o seleccione otro tipo de auto que no exija este requisito.',
    edadDriver2: 'Este auto sólo está disponible para conductores con edad: {edad1} - {edad2} años...',
    notDeleteItemRelationsContrate:
      'Upsss!!!... no es posible eliminar este item porque tiene relaciones con contratos de {contrate}.',
    notDeleteItemRelationsProduct:
      'Upsss!!!... no es posible eliminar este item porque tiene relaciones con el producto {product}.',
    notDeleteItemRelationsMarcaAsociada:
      'Upsss!!!... no es posible eliminar este item porque tiene relaciones con {item}.',
    notDeleteItemRelationsAgents: 'Upsss!!!... no es posible eliminar este item porque tiene relaciones con {item}.',
    noRelease: 'No es posible mostrar disponibilidad con menos de {days} días (Release) para el inicio del servicio.',
    payDayGrace: 'Cobrar +{days} día de renta',
    sendNewletter: 'Enviando mensaje a los destinatarios',
    sendNotification: 'Se le ha notificado al cliente',
    sendCotization: 'La cotización ha sido enviada a su cliente.',
    msgImgsNotSize: 'Las siguientes imágenes no cumplen con las dimensiones mínimas ({dimensions})',
    reviewAmounts: 'Por favor, revice los montos establecidos.',
    cxPSameProv:
      'No es posible emitir este pago, verifique que todas las Cuentas por pagar seleccionadas pertenecen al mismo proveedor.',
    msgCaduquedCotization: 'La cotización con código {code} ha caducado.',
    msgSendEmail: 'Se ha enviado el email a {email}.',
    msgCaducaLink1:
      'El documento al que intenta acceder pudo haber caducado. Le sugerimos escanear el código QR o comprobar el número nuevamente.',
    msgCaducaLink2: 'Si el problema se repite por favor contacte a Soporte ({email}, {phone}).',
    msgChangeRooms:
      'Las habitaciones del Hotel {hotel} que está intentando modificar están conectadas al Channel Manager Hotetec por lo que debe estar seguro de la acción que está realizando.',
    noResultFiltrado: 'No existe resultados para la búsqueda efectuada. Por favor, cambie los filtros y vuelva a intentarlo.',
    payConfirm: 'Su pago ha sido satisfactorio. Disfrute del servicio.',
    noTransactions: 'Sin transacciones.',
    errorRefund: 'No fue posible la conección con la pasarela de pago. Intente más tarde por favor.',
    errorRefundMonto: 'Está intentando reembolsar un valor no permitido.',
    noReservas: 'Sin reservas.',
    noCotizations: 'Sin cotizaciones.',
    changeFilters: 'Por favor cambie los filtros.',
    hotelsWithTarifa: 'Ya los siguientes hoteles tienen esa tarifa: {hotels}',
    saveChangesTarifa: 'Antes de cambiar de tarifa debe guardar los cambios realizados, de lo contrario estos cambios se perderán.',
    saveChangesCambios: 'Antes de abandonar la página debe guardar los cambios realizados, de lo contrario estos cambios se perderán.',
    saveTarifas: 'Se guardó la información del hotel {hotel} para el ctto {identificador} en la base de datos, y se actualizaron los precios en el tarifario.',
  },
  password: {
    security: 'Elige una contraseña más segura. Prueba con una combinación de letras, números y símbolos.',
    min_rules: 'Utilice ocho caracteres como mínimo, con una combinación de letras, números y símbolos.',
    title: 'La contraseña debe contener',
    not_math: 'Las contraseñas no coinciden. Inténtelo de nuevo.',
    has_minimum_lenth: 'Al menos 8 carácteres',
    has_lowercase: 'Una letra minúscula',
    has_uppercase: 'Una letra mayúscula',
    has_number: 'Un número',
    has_special: 'Un carácter especial',
  },
  menu: {
    dashboard: 'Tablero',
    subheader: 'HERRAMIENTAS',
    acceso: 'Acceso',
    booking: 'Booking',
    comitions: 'Cambio de moneda',
    finanzas: 'Finanzas',
    ctas: 'Cuentas',
    ctasXC: 'Cuentas por cobrar',
    ctaXC: 'Cuenta por cobrar',
    ctasXP: 'Cuentas por pagar',
    ctaXP: 'Cuenta por pagar',
    pays: 'Pagos',
    paysRec: 'Pagos recibidos',
    paysEmit: 'Pagos emitidos',
    cotizador: 'Cotizador',
    reservas: 'Reservas',
    tarifarios: 'Tarifarios',
    tarifariosNetos: 'Tarifarios Netos',
    cotizations: 'Cotizaciones',
    pvp: 'PVP',
    contability: 'Contabilidad',
    cuentasXCobrar: 'Cuentas x cobrar',
    cuentasXPagar: 'Cuentas x pagar',
    cuentasAfiliados: 'Estado cta Afiliados',
    nomenclador: 'Nomencladores',
    suplementos: 'Suplementos',
    suplementosReduc: 'Suplementos / Reducciones',
    reducciones: 'Reducciones',
    typeAfiliado: 'Tipo de afiliado',
    categoryCondition: 'Categorías de Condiciones',
    conditionGenerals: 'Condiciones Generales',
    permisos: 'Permisos',
    roles: 'Roles',
    perfilUser: 'Perfiles',
    users: 'Usuarios',
    usersSon: 'Usuarios Hijos',
    usersPropio: 'Propios',
    usersAdmin: 'Administrador',
    userRegister: 'Afiliados',
    companies: 'Compañías',
    agentes: 'Agentes',
    operations: 'Operaciones',
    logs: 'Logs',
    markups: 'Markups',
    stopSale: 'Paro de venta',
    oferts: 'Ofertas',
    export: 'Exportar',
    administration: 'Administración',
    gestion: 'Gestión',
    configuration: 'Configuración',
    dataGeneral: 'Propietario',
    asistencia: 'Asistencia',
    jsonAirport: 'JSON Aeropuertos',
    products: 'Productos',
    flights: 'Vuelos',
    cars: 'Autos',
    hotels: 'Hoteles',
    contratos: 'Contratos',
    class: 'Clases',
    tarifas: 'Tarifas',
    tarifasXKm: 'Tarifas por km',
    airport: 'Aeropuertos',
    aeronave: 'Aeronaves',
    aerolinea: 'Aerolíneas',
    dimensiones: 'Dimensiones equipaje',
    dimensione: 'Dimensiones',
    pesos: 'Pesos equipaje',
    peso: 'Pesos',
    cardFidelidad: 'Tarjetas de Fidelidad',
    card: 'Tarjetas',
    codeOFAC: 'Códigos OFAC',
    politicaTarifa: 'Política Tarifaria',
    politicaTarifas: 'Políticas Tarifarias',
    code: 'Códigos',
    trasnmision: 'Transmisión',
    transmisiones: 'Transmisiones',
    typeCar: 'Carrocería',
    types: 'Tipos',
    marca: 'Marca',
    marcas: 'Marcas',
    modelo: 'Modelo',
    modelos: 'Modelos',
    combustible: 'Combustible',
    combustibles: 'Combustibles',
    caracteristicas: 'Características',
    category: 'Categorías',
    rentadora: 'Rentadora',
    rentadoras: 'Rentadoras',
    oficinaRenta: 'Oficinas de renta',
    oficinas: 'Oficinas',
    ptoRecogida: 'Punto de recogida',
    ptos: 'Puntos',
    edades: 'Edades',
    typeDestination: 'Tipo de destinos',
    operador: 'Operador',
    marcaAsociada: 'Marca Asociada',
    intereses: 'Intereses',
    servicios: 'Servicios y Facilidades',
    habitaciones: 'Habitaciones',
    categoriesTarifarios: 'Categorías de Tarifarios',
    regimenAlimenticio: 'Régimen alimenticio',
    edadMin: 'Edad mínima',
    tagsImg: 'Etiquetas de Imágenes',
    tutorials: 'Tutoriales',
    transfers: 'Traslados',
    excursions: 'Excursiones',
    packages: 'Paquetes',
    cruise: 'Cruceros',
  },
  rules: {
    min6: 'Al menos 6 carácteres',
    upload1MB: '¡El tamaño del avatar debe ser inferior a 1 MB!',
  },
  footer: {
    allRights: 'Todos los derechos reservados',
    powered: 'Powered by',
  },
  user: {
    profile: 'Perfil',
    logout: 'Salir',
    data: 'Datos Generales',
    secuirty: 'Seguridad',
  },
  permiso: {
    zonas: 'Zonas',
    moderations: 'Moderaciones',
    chatbot: 'ChatBot',
    configuration: 'Configuración',
    messagesInit: 'Mensajes Automáticos',
    talking: 'Conversaciones',
    profils: 'Perfiles',
    ver: 'Ver',
    edit: 'Editar',
    create: 'Crear',
    delete: 'Eliminar',
    views: 'Vistas',
    tableros: 'Tableros',
    permiso: 'Permisos',
    roles: 'Roles',
    perfilUser: 'Perfiles de afiliados',
    usuarios: 'Usuarios',
    typeAfiliado: 'Tipo de afiliado',
    agente: 'Agentes',
    userRegister: 'Usuarios registrados',
    markups: 'Markups',
    configData: 'Configuración General',
    flights: 'Vuelos',
    contrate_flights: 'Contratos Vuelos',
    class: 'Clases',
    aeronave: 'Aeronaves',
    aerolinea: 'Aerolíneas',
    tarifa_flights: 'Tarifas',
    dimensiones: 'Dimensiones equipaje',
    pesos: 'Pesos equipaje',
    logs: 'Logs',
    cars: 'Autos',
    trasnmision: 'Transmisión',
    typeCar: 'Tipo de Auto',
    marca: 'Marca',
    modelo: 'Modelo',
    combustible: 'Combustible',
    caracteristicas: 'Características',
    category: 'Categorías',
    rentadora: 'Rentadora',
    oficinaRenta: 'Oficinas de renta',
    ptoRecogida: 'Punto de recogida',
    edadConductor: 'Edad del Conductor',
    tarifas: 'Tarifas',
  },
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
}
